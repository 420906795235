import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import EstimateForm from "./estimate-form";
import CardGeneral from "./card-general";
import HeroRatingStars from "./hero-rating-stars";
import { monthNames } from "../helpers/all";
import ContactForm from "./ContactForm";
import { CalendarCheck, CalendarClock, Zap } from "lucide-react";

const FORMOFFSETPX = 350;

const headerOffsetTransparent = 95;
  
const BadgeItem = styled.div`
  flex: 0 0 25%;
  overflow: hidden;
  max-width: 110px;
  padding: 0.5em 0.75em;
  &:last-child {
    padding-right: 0;
  }
`;
const BadgeImage = (props) => {
  return (
    <BadgeItem>
      <GatsbyImage
        image={getImage(props.item)}
        objectFit="contain"
        imgStyle={{ objectFit: "contain" }}
        className="badgeImage"
        alt={props.item.title}
        loading="eager"
      />
    </BadgeItem>
  );
};

// const Badges = (props) => {
//   props.badges.map((item, i) => {
//     console.log("BADGE ITEM")
//     return (
//       <BadgeImage item={item} key={i} />
//     )
//   })
// }

const ServiceHero = ({
  pageData,
  heroImage,
  serviceTitle,
  excerpt,
  specialOffer,
  offerDisclaimer,
  pageProps,
  badges,
  hideForm,
  hideRating,
  SecondaryColumnComponent,
}) => {
  //const FORMOFFSETPX = hideForm || SecondaryColumnComponent ? 0 : 350

  const dateInFiveDays = new Date();
  // deal expires in +5 days
  dateInFiveDays.setDate(dateInFiveDays.getDate() + 5);

  //date day suffix
  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const headerTransparent = false;

  var dealDateStringOutput =
    monthNames[dateInFiveDays.getUTCMonth() % 12] +
    " " +
    dateInFiveDays.getUTCDate() +
    nth(dateInFiveDays.getUTCDate());

  return (
    <>
      <div
        className="overflow-hidden"
        style={{
          marginTop: `${headerTransparent ? `${headerOffsetTransparent * -1}px` : "0"}`,
        }}
      >
        <div className="h-auto bg-cover relative bg-black ">
          {heroImage && (
            <GatsbyImage image={getImage(heroImage)} className="hero-image absolute top-0 h-full" loading="eager" />
          )}

          <div
            className="relative"
            style={{
              background: "linear-gradient(0deg, transparent, rgb(0 0 0 / 30%))",
              paddingTop: `${headerTransparent ? `${headerOffsetTransparent}px` : "0"}`,
            }}
          >
            <div
              className={`max-w-7xl py-8 carousel-content-container flex flex-wrap justify-between relative mx-auto px-4 md:px-6`}
            >
              <div className="w-full md:w-1/2 lg:w-2/3 py-12 md:py-16 md:pr-8 lg:pr-32 pb-4 flex flex-col justify-around">
               <div>
               <h1 className="text-shadow-lg text-center md:text-left text-white text-shadow service-heading-tag relative mb-2 font-display font-extrabold text-3xl sm:text-3xl md:text-5xl text-white leading-10">
                    {serviceTitle}
                  </h1>

                  {excerpt ? (
                    <p className="text-shadow-md text-center md:text-left text-white mt-1 text-base md:text-xl leading-7 py-2">
                      {excerpt}
                    </p>
                  ) : null}

               </div>
                <div>
             
                  {!hideRating && <HeroRatingStars centerMobile theme="light" classes="text-center md:text-left justify-center mb-8" />}
                  {badges ? (
                    <div className="mb-10 md:mb-0 flex items-center md:flex-wrap mt-3 w-full space-x-4 ">
                      {badges.map((badge, b) => {
                        return (
                          <div className=" p-3 flex items-center" style={{ maxWidth: "100px", height: "40px" }}>
                            <GatsbyImage
                              image={getImage(badge)}
                              objectFit="contain"
                              imgStyle={{ objectFit: "contain" }}
                              alt={badge.title}
                              loading="eager"
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
                {SecondaryColumnComponent && <SecondaryColumnComponent />}
              </div>
              <div className="w-full md:w-1/2 lg:w-1/3 form-offset">
                <div className="bg-white  border-t-4 border-brand-600 rounded-xl p-8 pb-6 border-brand-500 md-mb-none shadow-xl relative">
                  {/* <div
                    style={{ transform: "rotate(10deg)", top: "-12px" }}
                    class="special-tag rounded-r-none absolute bg-orange-500 font-display font-semibold inline-flex items-center mb-2 px-3 py-2 right-0 rounded-full uppercase text-sm text-white"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="text-white w-5 h-5 mr-1">
                      <path
                        fill="currentColor"
                        d="M48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L410.7 218.7C435.7 243.7 435.7 284.3 410.7 309.3L277.3 442.7C252.3 467.7 211.7 467.7 186.7 442.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5V80C0 53.49 21.49 32 48 32L48 32zM112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176z"
                      />
                      <path
                        fill="currentColor"
                        className="opacity-50"
                        d="M311.1 38.93C320.5 29.61 335.7 29.7 344.1 39.13L472.8 168.4C525.1 221.4 525.1 306.6 472.8 359.6L360.8 472.9C351.5 482.3 336.3 482.4 326.9 473.1C317.4 463.8 317.4 448.6 326.7 439.1L438.6 325.9C472.5 291.6 472.5 236.4 438.6 202.1L310.9 72.87C301.5 63.44 301.6 48.25 311.1 38.93V38.93z"
                      />
                    </svg>
                    <span className="font-display text-xs">Online Special</span>
                  </div> */}
                   <div className="inline-block pointer-events-none text-[0.8125rem] font-semibold bg-[#FFEB3B] mb-2 py-1 px-2 justiify-center mx-auto rounded">
                      Online Special
                    </div>

                  {/* <div className="bg-neutral-100 text-brand-600 -m-8 mb-8 rounded-t-xl flex items-center justify-center font-normal p-2"><CalendarCheck size={16} fill="#fff" className="mr-2"/> Free Same Day Estimates</div> */}



                  <div className="mb-4">
                    <h3 className="font-display leading-6 md:leading-7 font-extrabold text-2xl text-neutral-800">
                      {specialOffer ? specialOffer : "Request " + serviceTitle + " Consultation"}
                    </h3>
                    {/* <div className="specialsubheading">{offerDisclaimer}</div> */}
                    <p className="text-neutral-600 mb-2 leading-6 mt-2">
                      Request Free Pricing Today for Your Remodeling Needs!
                    </p>
                  </div>
                  <ContactForm hideLabels={true} showPlaceholders={true} footerContained={true} />

                  {/* <EstimateForm
                      style={'minimal-hero'}
                      showPlaceHolders={true}
                      showLabels={false}
                      pageTitle={serviceTitle}
                      pageProps={pageProps}
                      pageLocation={
                        pageProps.location.href
                          ? pageProps.location.href
                          : 'Not provided'
                      }
                    /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: FORMOFFSETPX + "px" }} className="height-none-md" />
      </div>
    </>
  );
};

export default ServiceHero;
